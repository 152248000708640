import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import timeZone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timeZone)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const getTimeFromNow = (date) => {
    return dayjs(date).fromNow();
}

const dateToISOString = (date) => {
    if (!date) {
        return dayjs().toISOString();
    }
    return dayjs(date).toISOString();
}

const ISOStringToDate = (date) => {
    if (!date) {
        return null;
    }
    return dayjs(date);
}

const convertToTimeZone = (date, timeZone = 'Australia/Perth') => {
    return dayjs(date).tz(timeZone);
}

const convertToLocalDate = (date) => {
    date = convertToTimeZone(date);
    return date.set('millisecond', 0).set('second', 0).set('minute', 0).set('hour', 0);
}

const isSameDate = (date1, date2) => {
    date1 = convertToTimeZone(date1);
    date2 = convertToTimeZone(date2);
    return date1.isSame(date2, 'day');
}

const isSameOrAfterDate = (date1, date2) => {
    date1 = convertToTimeZone(date1);
    date2 = convertToTimeZone(date2);
    return date1.isSameOrAfter(date2, 'day');
}

const isSameOrBeforeDate = (date1, date2) => {
    date1 = convertToTimeZone(date1);
    date2 = convertToTimeZone(date2);
    return date1.isSameOrBefore(date2, 'day');
}

export { 
    dateToISOString,
    getTimeFromNow,
    ISOStringToDate,
    convertToTimeZone,
    convertToLocalDate,
    isSameDate,
    isSameOrAfterDate,
    isSameOrBeforeDate
};
