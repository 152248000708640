<template>
  <div>
    <div class="field p-fluid">
      <label for="tarps">Tarps</label>
      <div class="flex justify-center p-fluid py-2">
        <Button label="Add Tarp" severity="info" @click="openNewTarpModal" />
      </div>
    </div>
    <div>
      <div class="selected-tarps-list">
        <TarpListItem v-for="(tarp, idx) in tarps"
          :key="tarp.client_id" 
          :tarp="tarp"
          :showLocation="false">
          <template #button>
            <Button severity="danger" size="small" text @click="removeTarp(tarp)">
              <template #icon>
                <span class="material-symbols-outlined">delete</span>
              </template>
            </Button>
          </template>
        </TarpListItem>
        <div class="text-center mt-2" v-if="tarps.length === 0">
          <p class="text-gray-500">No tarps created.</p>
        </div>
      </div>
    </div>

    <NewTarpModal
      ref="newTarpModal"
      :visible="newTarpModalVisible"
      :formOptions="formOptions"
      @onCreate="confirmCreateNewTarp"
      @close="closeNewTarpModal"
    />

  </div>
</template>

<script>
import NewTarpModal from '@/site_components/NewTarpModal/index.vue';
import TarpListItem from '@/site_components/SelectTarpsMenu/TarpListItem.vue';
import Tarp from '@/stores/objects/Tarp';

import Button from 'primevue/button';

export default {
  name: "TarpsSelectField",
  components: {
    TarpListItem,
    NewTarpModal,
    Button,
  },
  props: {
    modelValue: {
      // Array of Tarp objects
      type: Array,
      default: () => [],
    },
  },
  data() { return {
    newTarpModalVisible: false,
    formOptions: {
      serialNumberType: 'double',
      generateSerialNumber: false,
      autoCompleteSerialNo: true,
      setDefaults: true,
    }
  }},
  computed: {
    tarps() {
      return this.modelValue?.sort(
        (a, b) => a.serial_no - b.serial_no
      ).map(t => new Tarp().fromJSON(t)) || [];
    },
  },
  methods: {
    openNewTarpModal() {
      this.newTarpModalVisible = true;
    },
    closeNewTarpModal() {
      this.newTarpModalVisible = false;
    },

    confirmCreateNewTarp(newTarp) {
      // Triggered by NewTarpModal

      const updatedModelValue = [ ...this.modelValue, newTarp ]
      this.update(updatedModelValue);

      this.newTarpModalVisible = false;
    },
    removeTarp(tarp) {
      const newTarpsList = this.tarps.filter(t => t.client_id !== tarp.client_id);
      this.update(newTarpsList);
    },
    update(tarps) {
      this.$emit('update:modelValue', tarps);
    },

  }
}

</script>

<style scoped>
.selected-tarps-list {
  margin: 0.5rem -0.5rem;
}
</style>