import { default as axios } from './http';


class GCMManagementInputsAPI {

    getGCMManagementInputs = async () => {
        // Fetch stacks from API
        console.log('Fetching GCM Management Inputs from API...')
        const url = '/api/v1/gcm/management-inputs/';
        const response = await axios.get(url)
        return response
    }

}

export default GCMManagementInputsAPI;
