import { ISOStringToDate, dateToISOString } from "@/utils/dates";


class GCMManagementInputs {

  id = null;
  gcm_start_date = null;
  meters_per_manhour_kpi = null;

  // Adapters

  toJSON() {
    return {
      id: this.id,
      gcm_start_date: dateToISOString(this.gcm_start_date),
      meters_per_manhour_kpi: this.meters_per_manhour_kpi
    }
  }

  fromJSON(data) {
    this.id = data.id;
    this.gcm_start_date = (data.gcm_start_date) ?
      ISOStringToDate(data.gcm_start_date) : null;
    this.meters_per_manhour_kpi = (data.meters_per_manhour_kpi) ?
      parseFloat(data.meters_per_manhour_kpi) : null;
    return this
  }

}

export default GCMManagementInputs