import { defineStore } from 'pinia';
import { addItems, clearStore, getOrCreateItem, updateStoreLastUpdated, getItems } from './idb.js';

import { useGCMManagementInputsStore } from './GCMManagementInputs.js';
import GCMSwing from '@/stores/objects/GCMSwing';
import GCMSwingsAPI from './api/GCMSwings.js';


export const useGCMSwingsStore = defineStore('gcmSwings', {
  state: () => ({ 
    gcmSwings: [],
    lastUpdated: null,
  }),

  actions: {
    
    async loadGCMSwingsFromAPI() {
      // Fetch gcmSwings from API and update indexedDB
      const api = new GCMSwingsAPI();
      const response = await api.getGCMSwings();

      const data = response.data;
      this.gcmSwings = data;
      this.updateGCMSwingsDB(data);

      return response
    },

    async loadGCMSwingsFromDB() {
      console.log('Loading gcmSwings from indexedDB...')
      const gcmSwings = await getItems('gcmSwings')
      console.log(`${gcmSwings.length} gcmSwings loaded from indexedDB`);
      this.gcmSwings = gcmSwings;

      const gcmSwingsMeta = await getOrCreateItem('meta', 'gcmSwings');
      const lastUpdated = gcmSwingsMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadGCMSwings(awaitAPI) {
      // Load gcmSwings from cache and then update from API in background
      await this.loadGCMSwingsFromDB();
      if (navigator.onLine) {
        const req = this.loadGCMSwingsFromAPI();
        if (awaitAPI === true) await req;
      }
    },

    async updateGCMSwingsDB(data) {
      // Clears and repopulates tarp works in indexedDB
      clearStore('gcmSwings');

      // Add data
      addItems('gcmSwings', data);

      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      updateStoreLastUpdated('gcmSwings', now);
    },
  },

  getters: {

    getGCMSwings(state) {
      return state.gcmSwings.map(gcmSwing => new GCMSwing().fromJSON(gcmSwing));
    },

    getGCMSwingById: (state) => (id) => {
      return new GCMSwing().fromJSON(state.gcmSwings.find(gcmSwing => gcmSwing.id === id));
    },

    getSeasonTotalHours(state) {
      const gcmManagementInputsStore = useGCMManagementInputsStore();
      const startDate = gcmManagementInputsStore.getGCMStartDate;

      let swings;
      (startDate) ? 
        swings = state.gcmSwings.filter(gcmSwing => new Date(gcmSwing.end_date) >= startDate) : 
        swings = state.gcmSwings;

      return swings.reduce((total, gcmSwing) => total + Number(gcmSwing.week_1_hours) + Number(gcmSwing.week_2_hours), 0);
    }

  }

})