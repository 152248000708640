import { defineStore } from 'pinia';
import { addItems, clearStore, getOrCreateItem, updateStoreLastUpdated, getItems } from './idb.js';

import GCMManagementInputs from '@/stores/objects/GCMManagementInputs';
import GCMManagementInputsAPI from './api/GCMManagementInputs.js';


export const useGCMManagementInputsStore = defineStore('gcmManagementInputs', {
  state: () => ({ 
    gcmManagementInputs: {},
    lastUpdated: null,
  }),

  actions: {
    
    async loadGCMManagementInputsFromAPI() {
      // Fetch gcmManagementInputs from API and update indexedDB
      const api = new GCMManagementInputsAPI();
      const response = await api.getGCMManagementInputs();

      const data = response.data;
      this.gcmManagementInputs = data;
      this.updateGCMManagementInputsDB([data]);

      return response
    },

    async loadGCMManagementInputsFromDB() {
      console.log('Loading gcmManagementInputs from indexedDB...')
      const gcmManagementInputs = await getItems('gcmManagementInputs')
      console.log(`gcmManagementInputs loaded from indexedDB`);
      this.gcmManagementInputs = gcmManagementInputs[0] || {};

      const gcmManagementInputsMeta = await getOrCreateItem('meta', 'gcmManagementInputs');
      const lastUpdated = gcmManagementInputsMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadGCMManagementInputs(awaitAPI) {
      // Load gcmManagementInputs from cache and then update from API in background
      await this.loadGCMManagementInputsFromDB();
      if (navigator.onLine) {
        const req = this.loadGCMManagementInputsFromAPI();
        if (awaitAPI === true) await req;
      }
    },

    async updateGCMManagementInputsDB(data) {
      // Clears and repopulates tarp works in indexedDB
      clearStore('gcmManagementInputs');

      // Add data
      console.log(data);
      addItems('gcmManagementInputs', data);

      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      updateStoreLastUpdated('gcmManagementInputs', now);
    },
  },

  getters: {

    getGCMManagementInputs(state) {
      return new GCMManagementInputs().fromJSON(state.gcmManagementInputs);
    },

    getGCMStartDate(state) {
      return (state.gcmManagementInputs.gcm_start_date) ?
        new Date(state.gcmManagementInputs.gcm_start_date) : null;
    },

    getMetersPerManhourKPI(state) {
      return (state.gcmManagementInputs.meters_per_manhour_kpi) ?
        parseFloat(state.gcmManagementInputs.meters_per_manhour_kpi) : null;
    }

  }

})