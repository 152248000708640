<template>
    <div>
        <NavigationAction v-show="showReset" label="Reset" @click="reset" />
        <ConfirmDialog acceptLabel="Reset form">
            <template #accepticon>
                <div></div>
            </template>
        </ConfirmDialog>
    </div>
</template>

<script>
import { useRecordGCMStore } from '@/stores/RecordGCM';

import ConfirmDialog from 'primevue/confirmdialog';
import NavigationAction from '@/navigation/TopNavigationBar/components/NavigationAction.vue'

export default {
    name: "ResetGCMFormButton",
    components: {
        ConfirmDialog,
        NavigationAction,
    },
    data() { return {
        recordGCMStore: null,
    }},
    created() {
        this.recordGCMStore = useRecordGCMStore();
    },
    computed: {
        showReset() {
            return true;
        }
    },
    methods: {
        reset() {
            this.$confirm.require({
                message: 'Are you sure you want to reset this form? All progress will be lost.',
                header: 'Reset Form',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.recordGCMStore.reset();
                    
                    const event = new CustomEvent('resetGCM');
                    window.dispatchEvent(event);
                }
            });
        }
    }
}

</script>