<template>
  <div>
    <div v-if="startDate && !loading">
      <div class="flex flex-column">
        <div class="row flex surface-overlay">
          <div class="cell font-bold">
            GCM (m)
          </div>
          <div class="cell font-bold">
            GCM (m) / MH
          </div>
          <div class="cell font-bold">Repairs</div>
          <div class="cell font-bold">Joins</div>
        </div>
        <div class="row flex surface-overlay">
          <div class="cell font-bold">
            {{ gcmTotalMeters ?? '' }}
          </div>
          <div :class="`cell font-bold ${metersPerHourBgClass}`">
            {{ gcmMetersPerHour }}
          </div>
          <div class="cell font-bold p-0">
            <div class="flex">
              <div class="cell font-bold flex-grow-1 border-right-only">
                S
              </div>
              <div class="cell font-bold flex-grow-1 border-left-only">
                E
              </div>
            </div>
          </div>
          <div class="cell font-bold p-0">
            <div class="flex">
              <div class="cell font-bold flex-grow-1 border-right-only">
                S
              </div>
              <div class="cell font-bold flex-grow-1 border-left-only">
                W
              </div>
            </div>
          </div>
        </div>
        <div class="row flex bg-blue text-white">
          <div class="cell font-bold">Start Date</div>
          <div class="cell font-bold">{{ startDateDisplay }}</div>
          <div class="cell font-bold p-0">
            <div class="flex">
              <div class="cell font-bold flex-grow-1 border-right-only">
                {{ swingTotals.repairs_straights }}
              </div>
              <div class="cell font-bold flex-grow-1 border-left-only">
                {{ swingTotals.repairs_ends }}
              </div>
            </div>
          </div>
          <div class="cell font-bold p-0">
            <div class="flex">
              <div class="cell font-bold flex-grow-1 border-right-only">
                {{ swingTotals.joins_sews }}
              </div>
              <div class="cell font-bold flex-grow-1 border-left-only">
                {{ swingTotals.joins_welds }}
              </div>
            </div>
          </div>
        </div>
        <div v-for="(_date, idx) in swingDateTotals" :key="idx" class="row flex">
          <div class="cell">{{ dayOfWeek(_date.date) }}</div>
          <div class="cell">{{ dateDisplay(_date.date) }}</div>
          <div class="cell p-0">
            <div class="flex">
              <div class="cell flex-grow-1 border-right-only">{{ _date.repairs_straights }}</div>
              <div class="cell flex-grow-1 border-left-only">{{ _date.repairs_ends }}</div>
            </div>
          </div>
          <div class="cell p-0">
            <div class="flex">
              <div class="cell flex-grow-1 border-right-only">{{ _date.joins_sews }}</div>
              <div class="cell flex-grow-1 border-left-only">{{ _date.joins_welds }}</div>
            </div>
          </div>
        </div>
      
      </div>
    </div>

    <div v-else-if="startDate && loading">
      <div class="text-center mt-5">
        <ProgressSpinner :style="`width: 50px; height: 50px`" />
      </div>
    </div>
    
    <div v-else-if="!startDate">
      <div class="text-center mt-5">
        <p class="text-gray-500">Select a start date to generate table summary</p>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

import { convertToLocalDate, isSameDate } from '@/utils/dates';

import { useGCMManagementInputsStore } from '@/stores/GCMManagementInputs';
import { useGCMSwingsStore } from '@/stores/GCMSwings';
import { useTarpJoinsStore } from '@/stores/TarpJoins';
import { useTarpRepairsStore } from '@/stores/TarpRepairs';

export default {
  name: "SwingSummaryTable",
  components: {
    ProgressSpinner,
  },
  props: {
    startDate: {
      type: [String, null],
      required: true
    },
    swingDaysLength: {
      type: Number,
      default: 9
    }
  },
  data() { return {
    loading: false,
    swingDateTotals: [],
    swingTotals: {
      repairs_straights: 0,
      repairs_ends: 0,
      joins_sews: 0,
      joins_welds: 0,
    },
    gcmTotalMeters: 0,
    gcmMetersPerHour: '',
  }},
  created() {
    this.gcmManagementInputsStore = useGCMManagementInputsStore();
  },

  watch: {
    startDate() {
      this.$nextTick(() => {
        // Pause for a moment to allow other components to update
        // Provides a smoother UX
        this.loading = true;
        setTimeout(() => {
          this.calculateSwingTotals();
          this.loading = false;
        }, 500);
      });
    }
  },

  computed: {
    _startDate() {
      return new Date(this.startDate);
    },
    _endDate() {
      const d = new Date(this._startDate);
      d.setDate(d.getDate() + this.swingDaysLength);
      return d;
    },
    startDateDisplay() {
      const d = this._startDate;
      return `${d.getDate()} ${d.toLocaleString('default', { month: 'short' })}`;
    },

    swingDates() {
      // Return all the dates in the swing
      const dates = [];
      for (let i = 0; i < this.swingDaysLength; i++) {
        const d = new Date(this._startDate);
        d.setDate(d.getDate() + i);
        dates.push(d);
      }
      return dates
    },

    metersPerHourBgClass() {
      const kpi = this.gcmManagementInputsStore.getMetersPerManhourKPI;
      const actual = Number(this.gcmMetersPerHour);
      if (kpi && actual !== NaN && actual < kpi) {
        return 'bg-red';
      } else {
        return 'bg-green';
      }
    }

  },
  methods: {
    dayOfWeek(date) {
      return date.toLocaleString('default', { weekday: 'short' });
    },
    dateDisplay(date) {
      return date.toLocaleString(undefined, { 
        day: 'numeric',
        month: 'short',
       }
      );
    },

    calculateSwingTotals() {
      const repairs = useTarpRepairsStore().getTarpRepairsForDateRange(this._startDate, this._endDate, true);
      const joins = useTarpJoinsStore().getTarpJoinsForDateRange(this._startDate, this._endDate);

      this.swingTotals = {
        repairs_straights: 0,
        repairs_ends: 0,
        joins_sews: 0,
        joins_welds: 0,
      };
      this.swingDateTotals = [];

      for (let i = 0; i < this.swingDaysLength; i++) {
        const d = new Date(this._startDate);
        d.setDate(d.getDate() + i);

        const localDate = convertToLocalDate(d);

        const repairsForDate = repairs.filter(r => isSameDate(r.completion_time, localDate));
        const repairsStraights = repairsForDate.filter(r => !r.base_tarp || r.base_tarp?.cover_type === 'straight');
        const repairsEnds = repairsForDate.filter(r => r.base_tarp?.cover_type === 'end');

        const joinsForDate = joins.filter(j => isSameDate(j.completion_time, localDate));
        const joinsSews = joinsForDate.filter(j => j.join_type === 'sew');
        const joinsWelds = joinsForDate.filter(j => j.join_type === 'weld');

        const joinsSewsCount = joinsSews.reduce((acc, join) => acc + join.joinsCount(), 0);
        const joinsWeldsCount = joinsWelds.reduce((acc, join) => acc + join.joinsCount(), 0);

        const dateData = {
          date: d,
          repairs_straights: repairsStraights.length,
          repairs_ends: repairsEnds.length,
          joins_sews: joinsSewsCount,
          joins_welds: joinsWeldsCount,
        };

        this.swingDateTotals.push(dateData);

        this.swingTotals.repairs_straights += repairsStraights.length;
        this.swingTotals.repairs_ends += repairsEnds.length;
        this.swingTotals.joins_sews += joinsSewsCount;
        this.swingTotals.joins_welds += joinsWeldsCount;
      }

      let allRepairs = useTarpRepairsStore().getTarpRepairs
      allRepairs = allRepairs.filter(r => r.condition !== 'deleted_sn');

      const startDate = this.gcmManagementInputsStore.getGCMStartDate;

      let gcmRepairs;
      (startDate) ? 
        gcmRepairs = allRepairs.filter(r => r.completion_time >= startDate) :
        gcmRepairs = allRepairs;
      this.gcmTotalMeters = gcmRepairs.reduce((acc, r) => acc + Number(r.new_length), 0);
      this.gcmTotalMeters = this.gcmTotalMeters.toFixed(2);

      this.calculateMetersPerHour();

      return this.swingTotals;
    },

    calculateMetersPerHour() {
      const seasonHours = useGCMSwingsStore().getSeasonTotalHours;
      const seasonMeters = Number(this.gcmTotalMeters ?? 0);

      (seasonHours === 0) ? 
        this.gcmMetersPerHour = 'N/A' :
        this.gcmMetersPerHour = (seasonMeters / seasonHours).toFixed(2);

      return this.gcmMetersPerHour;
    }
  }
}

</script>

<style scoped>
.cell {
  padding: 10px;
  border: 1px solid black;
  width: 25%;
}
.cell:not(:first-child) {
  margin-left: -1px;
}
.row:not(:first-child) {
  margin-top: -1px;
}

.border-right-only {
  border: none;
  border-right: 1px solid black;

}
.border-left-only {
  border: none;
  border-left: 1px solid black;
}
  
</style>