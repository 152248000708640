import { ISOStringToDate, dateToISOString } from "@/utils/dates";


class GCMSwing {

  id = null;
  end_date = null;
  week_1_hours = null;
  week_2_hours = null;

  // Adapters

  toJSON() {
    return {
      id: this.id,
      end_date: dateToISOString(this.end_date),
      week_1_hours: this.week_1_hours,
      week_2_hours: this.week_2_hours
    }
  }

  fromJSON(data) {
    this.id = data.id;
    this.end_date = ISOStringToDate(data.end_date);
    this.week_1_hours = data.week_1_hours;
    this.week_2_hours = data.week_2_hours;
    return this
  }

}

export default GCMSwing