import { defineStore } from 'pinia';
import { 
  getOrCreateItem,
  updateStoreLastUpdated,
  saveItem,
  deleteItemsByIndex,
  getItems,
  addItems,
  clearStore,
} from './idb.js';

import TarpInventoryAPI from '@/stores/api/TarpInventory.js';
import Tarp from '@/stores/objects/Tarp';


export const useTarpInventoryStore = defineStore('tarpInventory', {
  state: () => ({ 
    tarps: [],
    lastUpdated: null,
  }),

  actions: {

    async loadTarpsFromAPI() {
      // Fetch tarps from API and update indexedDB
      const api = new TarpInventoryAPI();

      console.log('Fetching tarps from API...')

      try {
        const response = await api.getTarps();
        const data = response.data;
        this.tarps = data;
        this.updateTarpsDB(data);
        return response
      } catch (error) {
        throw error
      }
    },

    async loadTarpsFromDB() {
      console.log('Loading tarps from indexedDB...')
      const tarps = await getItems('tarps');
      this.tarps = tarps;

      const tarpsMeta = await getOrCreateItem('meta', 'tarps');
      const lastUpdated = tarpsMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadTarps(awaitAPI) {
      // Load tarps from cache and then update from API in background
      await this.loadTarpsFromDB();
      if (navigator.onLine) {
        const response = this.loadTarpsFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateTarpsDB(data) {
      // Update tarps indexedDB
      clearStore('tarps');

      // Add data
      addItems('tarps', data);
      
      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      updateStoreLastUpdated('siteWorks', now);
    },

    async handleStateChange(state_change) {
      const created = state_change?.created || null;

      // Only process state change if the created timestamp is within the last 30 seconds
      // Prevents stale state changes from being processed multiple times
      if (created) {
        const createdDate = new Date(created);
        const now = new Date();
        const diff = now - createdDate;
        if (diff > 30000) {
          console.log('Stale state change, ignoring...');
          return;
        }
      }

      const addedTarps = state_change?.added_tarps || [];
      const archivedTarps = state_change?.archived_tarps || [];

      if (addedTarps.length) {
        addedTarps.forEach(tarp => {
          this.addTarp(tarp);
        });
      }

      if (archivedTarps.length) {
        this.removeTarps(archivedTarps);
      }
    },

    async addTarp(tarp) {
      await saveItem('tarps', tarp.serial_no, tarp);
      this.tarps = [
        ...this.tarps.filter(t => t.serial_no !== tarp.serial_no), 
        tarp];
    },

    async removeTarps(tarpIds) {
      if (!tarpIds) return;
      if (!Array.isArray(tarpIds)) tarpIds = [tarpIds];
      await deleteItemsByIndex('tarps', 'id', tarpIds);
      this.tarps = [...this.tarps.filter(t => !tarpIds.includes(t.id))];
    },

    async updateTarp(tarp) {
      await saveItem('tarps', tarp.serial_no, tarp);
      this.tarps = [
        ...this.tarps.filter(t => t.serial_no !== tarp.serial_no), 
        tarp
      ].sort((a, b) => b.serial_no - a.serial_no);
    }
    
  },

  getters: {

    getTarps: (state) => {
      console.log('Getting tarps...');
      const tarps = state.tarps;
      const sortedTarps = tarps.sort((a, b) => {
        return b.serial_no - a.serial_no;
      });
      return sortedTarps.map(tarp => new Tarp().fromJSON(tarp));
    },

    getMaintainedTarps: (state) => {
      return state.tarps.filter(tarp => tarp.is_maintained);
    },

    getTarpById: (state) => (id) => {
      const data = state.tarps.find(tarp => tarp.id === id);
      if (data) return new Tarp().fromJSON(data);
      else {
        const tarp = new Tarp();
        tarp.id = id;
        tarp.serial_no = 'Archived';
        return tarp;
      };
    },

    getTarpBySerialNo: (state) => (serialNo) => {
      const data = state.tarps.find(tarp => tarp.serial_no === serialNo);
      if (data) return new Tarp().fromJSON(data);
      else      return null;
    },

    getTarpsBySite: (state) => (siteId) => {
      return state.tarps.filter(tarp => tarp.site === siteId);
    },

    getTarpIndex: (state) => (id) => {
      return state.tarps.findIndex(tarp => tarp.id === id);
    },

    filterTarps: (state) => {
      return (search, from=null, limit=50) => {

        let filteredTarps;
        if (search) {
          const searchRegex = new RegExp(search, 'i');
          filteredTarps = state.tarps.filter(tarp => {
            return tarp.serial_no.match(searchRegex);
          });
        } else {
          filteredTarps = state.tarps;
        }

        let fromIndex = 0;
        if (from) {
          fromIndex = filteredTarps.findIndex(tarp => tarp.id === from);
          if (fromIndex === -1) fromIndex = 0;
        }
        
        let moreResults = false;
        if (filteredTarps.length > fromIndex + limit) {
          moreResults = true;
        }

        const results = filteredTarps.slice(fromIndex, fromIndex + limit);

        console.log(`Returning ${results.length} tarps from ${fromIndex} to ${fromIndex + limit}`);

        return [[...results.map(tarp => new Tarp().fromJSON(tarp))], moreResults];
      }
    },

  }
})
