<template>
  <div>
    <form v-show="!store.submitted" @submit.prevent="submit" autocomplete="off">
      <FormHeader />
      <div class="mt-3">
        <FormSteps 
          :steps="formSteps" 
          :activeStepIndex="store.activeStepIndex"
          :disableAfterInvalidSteps="true"
          @setActiveStepIndex="setActiveStepIndex" />
      </div>
      
      <div 
        v-for="(step, index) in formSteps" 
        :key="step.name" 
        v-show="store.activeStepIndex === index">
        
        <Divider />

        <div v-for="field in step.fields" :key="field.name" class="field p-fluid">
          <div v-show="!field.isHidden">
            <component
              v-if="!field.isHidden"
              :is="field.component" 
              :modelValue="field.value || form[field.name]"
              @update:modelValue="updateField(field, $event)"
              :label="field.label" 
              :subtitle="field.subtitle"
              v-bind="field.props" />
            <Divider />
          </div>
        </div>

        <div class="field p-fluid">
          <Button
            v-if="index < formSteps.length - 1"
            label="Next"
            severity="secondary"
            size="large"
            :disabled="!step.isValid"
            type="button"
            @click="setActiveStepIndex(index + 1)"
            raised
          />
          <Button
            v-else
            label="Submit"
            type="submit"
            severity="secondary"
            :disabled="!step.isValid"
            size="large"
            raised
          />
        </div>
      </div>
    </form>
    <FormSubmitLoader
      v-if="store.submitted"
      :loading="store.loading"
      :status="responseStatus"
      :errors="responseErrors"
      @recordAnother="recordAnother"
      @returnToForm="returnToForm"
      @reSubmitForm="reSubmit"
    />
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

import { useRecordSiteWorksStore } from '@/stores/RecordSiteWorks';
import SiteWorks from '@/stores/objects/SiteWorks';

import IntegerField from '@/components/forms/IntegerField.vue';
import TextareaField from '@/components/forms/TextareaField.vue';
import FormHeader from '../../components/FormHeader.vue';
import CompletionTime from './fields/CompletionTime.vue';
import Site from './fields/Site.vue';
import MaintainedTarpsOnSite from './fields/MaintainedTarpsOnSite.vue';

import FormSteps from '@/components/forms/FormSteps.vue';
import FormSubmitLoader from '@/site_components/FormSubmitLoader/index.vue';

import Button from 'primevue/button';
import Divider from 'primevue/divider';

export default {
  name: "SiteWorksForm",
  components: {
    IntegerField,
    TextareaField,
    MaintainedTarpsOnSite,
    FormHeader,
    FormSteps,
    FormSubmitLoader,
    Site,
    CompletionTime,
    Button,
    Divider,
  },
  created() {
    this.store = useRecordSiteWorksStore();
    this.store.init();
  },
  computed: {
    form() {
      return this.store.site_works;
    },
    responseStatus() {
      return this.store.saveResponse?.status;
    },
    responseErrors() {
      return this.store.getErrors;
    },

    getSiteWorks() {
      return new SiteWorks().fromJSON(this.form);
    },

    siteWorksFields() {
      return [
        {
          name: 'site',
          component: 'Site',
        },
        {
          name: 'completion_time',
          component: 'CompletionTime',
        },
        {
          name: 'wozzy',
          component: 'IntegerField',
          label: 'Wozzy',
          subtitle: 'Enter the number of wozzy containers available on site.'
        },
        {
          name: 'ratchets',
          component: 'IntegerField',
          label: 'Ratchets',
          subtitle: 'Enter the number of ratchets available on site.',
          inputProps: {
            placeholder: 'Hello World',
          }
        },
        {
          name: 'belly_straps',
          component: 'IntegerField',
          label: 'Belly Straps',
          subtitle: 'Enter the number of belly straps available on site.'
        },
        {
          name: 'high_wall_canvecon',
          component: 'IntegerField',
          label: 'High Wall Canvecon',
          subtitle: `
            Enter the number of full (i.e. don’t count any rolls that have been open/used before) 
            high wall canvecon rolls  available on site.
          `
        },
        {
          name: 'low_wall_canvecon',
          component: 'IntegerField',
          label: 'Low Wall Canvecon',
          subtitle: `
            Enter the number of full (i.e. don’t count any rolls that have been open/used before) 
            low wall canvecon rolls  available on site.
          `
        },
        {
          name: 'small_end_wall_tee_pieces',
          component: 'IntegerField',
          label: 'Small Wall Tee-Pieces',
          subtitle: 'Enter the number of small wall tee-pieces available on site.'
        },
        {
          name: 'small_end_wall_sections',
          component: 'IntegerField',
          label: 'Small Wall Sections (In Grave Yard)',
          subtitle: `
            Enter the number of small wall sections compiled on site 
            (i.e. each small wall section comprises of 40 off struts, 40 off pins, 40 bolts, 40 nuts, 40 washers, 11 ridge caps, 11 boards, 11 tin sheets, etc.). 
            Pack up all components into a pile, strap them and spray paint with the year it was completed.
          `
        },
        {
          name: 'small_end_walls_still_inside_obhs',
          component: 'IntegerField',
          label: 'Small Walls Still Inside OBHs',
          subtitle: `
            Enter the number of Small Walls still inside the OBHs that are still in use 
            (i.e. tarps still clamped to them and they cannot be removed and packed up).
          `
        },
        {
          name: 'comments',
          component: 'TextareaField',
          label: 'Comments / Notes',
          subtitle: 'Please list any additional notes or comments.'
        },
      ];
    },

    tarpFields() {
      return [
        {
          name: 'number_of_outloaded_tarps_on_site',
          component: 'IntegerField',
          label: 'Number of Outloaded Tarps on Site',
        },
        {
          name: 'tarps_on_site',
          component: 'MaintainedTarpsOnSite',
        }
      ];
    },

    formSteps() {
      const siteWorks = this.getSiteWorks;

      return [
        {
          name: 'site_works',
          label: 'Site Works',
          fields: this.siteWorksFields,
          isValid: siteWorks.siteWorksIsValid()
        },
        {
          name: 'tarps',
          label: 'Tarps',
          fields: this.tarpFields,
          isValid: siteWorks.tarpsIsValid()
        }
      ]
    }
  },
  methods: {
    updateField(field, value) {
      if (field.update) {
        field.update(value);
      } else {
        this.form[field.name] = value;
      }
    },

    async submit() {
      this.store.loading = true;
      this.store.submitted = true;
      try {
        await this.store.saveSiteWorks();
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      } finally {
        // Store a local copy of the form that we can use to restore the form
        // if the user wants to modify the data. Otherwise, we need to reset the
        // store to clear the form.
        this.localData = this.store.site_works;
        // Reset the store
        this.store.reset(false);
        this.store.loading = false;
      }
    },

    recordAnother() {
      this.store.reset(true);
    },
    reSubmit() {
      this.store.site_works = this.localData;
      this.submit();
    },
    returnToForm() {
      this.store.site_works = this.localData;
      this.store.submitted = false;
    },
    setActiveStepIndex(index) {
      // Set scroll position to top
      window.scrollTo(0, 0);
      this.store.activeStepIndex = index;
    }
  }
}

</script>