<template>
  <div>
    <form v-show="!store.submitted" @submit.prevent="submit" autocomplete="off">

      <FormHeader />
      <div class="mt-3">
        <FormSteps 
          :steps="formSteps" 
          :activeStepIndex="store.activeStepIndex"
          :disableAfterInvalidSteps="true"
          @setActiveStepIndex="selectActiveStepIndex" />
      </div>

      <div 
        v-for="(step, index) in formSteps" 
        :key="step.name" 
        v-show="store.activeStepIndex === index">
        
        <Divider />

        <div v-for="field in step.fields" :key="field.name" class="field p-fluid">
          <div v-show="!field.isHidden">
            <component
              v-if="!field.isHidden"
              :is="field.component" 
              :modelValue="field.value || form[field.name]"
              @update:modelValue="updateField(field, $event)"
              :label="field.label" 
              :subtitle="field.subtitle"
              v-bind="field.props" />
            <Divider />
          </div>
        </div>

        <div class="field p-fluid">
          <Button
            v-if="index < formSteps.length - 1"
            label="Next"
            severity="secondary"
            size="large"
            :disabled="!step.isValid"
            type="button"
            @click="setActiveStepIndex(index + 1)"
            raised
          />
          <Button
            v-else
            label="Submit"
            type="submit"
            severity="secondary"
            :disabled="!step.isValid"
            size="large"
            raised
          />
        </div>

      </div>
    </form>
    <FormSubmitLoader
      v-if="store.submitted"
      :loading="store.loading"
      :status="responseStatus"
      :errors="responseErrors"
      @recordAnother="recordAnother"
      @returnToForm="returnToForm"
      @reSubmitForm="reSubmit"
    />
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

import { useRecordOBHWorksStore } from '@/stores/RecordOBHWorks';
import { useOBHWorksStore } from '@/stores/OBHWorks';
import { OBHWorks } from '@/stores/objects/OBHWorks';
import { OBH_SYSTEMS } from '@/stores/enums/OBHM';

import DropdownField from '@/components/forms/DropdownField.vue';
import IntegerField from '@/components/forms/IntegerField.vue';
import TextareaField from '@/components/forms/TextareaField.vue';
import FormHeader from '../../components/FormHeader.vue';

import CompletionTime from '@/components/forms/CompletionTime.vue';
import FormSteps from '@/components/forms/FormSteps.vue';
import FormSubmitLoader from '@/site_components/FormSubmitLoader/index.vue';
import RadioSelectField from '@/components/forms/RadioSelectField.vue';

import SiteSelect from '@/site_components/SiteSelect/index.vue';
import StorageSelect from '@/site_components/StorageSelect/index.vue';
import TarpsSelectField from '@/site_components/TarpsSelectField/index.vue';

import PartialProgressRecords from './components/PartialProgressRecordsField/index.vue';

import Button from 'primevue/button';
import Divider from 'primevue/divider';

export default {
  name: "SiteWorksForm",
  components: {
    DropdownField,
    IntegerField,
    TextareaField,
    FormHeader,
    FormSteps,
    FormSubmitLoader,
    RadioSelectField,
    SiteSelect,
    StorageSelect,
    TarpsSelectField,
    CompletionTime,
    PartialProgressRecords,
    Button,
    Divider,
  },
  computed: {
    form() {
      return this.store.obh_works;
    },
    responseStatus() {
      return this.store.saveResponse?.status;
    },
    responseErrors() {
      return this.store.getErrors;
    },

    getOBHWorks() {
      const obhWorks = new OBHWorks();
      obhWorks.fromJSON(this.store.obh_works);
      return obhWorks
    },

    requiredConsumables() {
      return this.getOBHWorks.getRequiredConsumables();
    },

    formSteps() {
      const obhWorks = this.getOBHWorks;

      const hasPreviousWorksForStorage = obhWorks.hasPreviousWorksForStorage();

      // Combine existing tarps and new tarps
      const tarpsOnStack = obhWorks.base_tarps_on_stack;
      const newTarps = obhWorks.new_tarps;
      if (newTarps.length > 0) {
        tarpsOnStack.push(...newTarps);
      }

      return [
        {
          name: 'obh',
          label: 'OBH',
          fields: [
            {
              name: 'completion_time',
              label: 'Completion Time',
              subtitle: 'The time these OBH works were completed',
              component: 'CompletionTime',
            },
            {
              name: 'site',
              label: 'Site',
              component: 'SiteSelect',
            },
            {
              name: 'storage',
              label: 'OBH',
              component: 'StorageSelect',
              props: {
                siteId: this.form.site,
              },
            },
            {
              name: 'storage_system',
              label: 'OBH System',
              component: 'DropdownField',
              props: {
                options: OBH_SYSTEMS,
                optionLabel: 'label',
                optionValue: 'value',
                placeholder: 'Select an OBH System',
              }
            }
          ],
          isValid: obhWorks.obhIsValid()
        },
        {
          name: 'progress',
          label: 'Progress',
          fields: [
            {
              name: 'obh_maintenance_is_complete',
              label: 'Is the OBH maintenance 100% complete?',
              component: 'RadioSelectField',
              props: {
                options: [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ],
                multiple: false,
              },
              update: (value) => {
                if (value === true) {
                  this.form.has_grain_in_storage = false;
                }
                this.form.obh_maintenance_is_complete = value;
              }
            },
            {
              name: 'obh_works_partial_progress_records',
              label: 'Partial Progress Records',
              component: 'PartialProgressRecords',
              isHidden: this.form.obh_maintenance_is_complete !== false,
            },
          ],
          isValid: obhWorks.progressIsValid()
        },
        {
          name: 'consumables',
          label: 'Consumables',
          fields: [
            {
              name: 'nuts',
              label: 'Nuts',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('nuts'),              
            },
            {
              name: 'bolts',
              label: 'Bolts',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('bolts'),
            },
            {
              name: 'clamps',
              label: 'Clamps',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('clamps'),
            },
            {
              name: 'toggles',
              label: 'Toggles',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('toggles'),
            },
            {
              name: 'slider_pins',
              label: 'Slider Pins',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('slider_pins'),
            },
            {
              name: 'poles',
              label: 'Poles',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('poles'),
            },
            {
              name: 'handles',
              label: 'Handles',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('handles'),
            },
            {
              name: 'ridgecaps',
              label: 'Ridgecaps',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('ridgecaps'),
            },
            {
              name: 'fiberglass_system_ridgecaps',
              label: 'Fiberglass System Ridgecaps',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('fiberglass_system_ridgecaps'),
            },
            {
              name: 'ias_clamping_system_ridgecaps',
              label: 'IAS Clamping System Ridgecaps',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('ias_clamping_system_ridgecaps'),
            },
            {
              name: 'boards',
              label: 'Boards',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('boards'),
            },
            
            {
              name: 'fiberglass_system_boards',
              label: 'Fiberglass System Boards',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('fiberglass_system_boards'),
            },
            {
              name: 'coarse_threaded_bolts',
              label: 'Coarse Threaded Bolts',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('coarse_threaded_bolts'),
            },
            {
              name: 'struts',
              label: 'Struts',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('struts'),
            },
            {
              name: 'pins',
              label: 'Pins',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('pins'),
            },
            {
              name: 'tin_sheets_fg_patches',
              label: 'Tin Sheets (FG Patches)',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('tin_sheets_fg_patches'),
            },
            {
              name: 'tin_sheets_1m',
              label: 'Tin Sheets (1m)',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('tin_sheets_1m'),
            },
            {
              name: 'tin_sheets_3m',
              label: 'Tin Sheets (3m)',
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('tin_sheets_3m'),
            },
            {
              name: 'fumigation_t_pieces_replaced',
              label: 'Fumigation T-Pieces Replaced',
              subtitle: "No. of replacement t-pieces used for maintenance.",
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('fumigation_t_pieces_replaced'),
            },
            {
              name: 'fumigation_t_pieces_required',
              label: 'Fumigation T-Pieces Still Required',
              subtitle: "Number of replacement t-pieces that are still required to complete 100% of maintenance.",
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('fumigation_t_pieces_required'),
            },
            {
              name: 'gates_rebuilt',
              label: 'Gates Re-Built',
              subtitle: `
                No. of Gates Re-Built, complete with conveyor belt attached to internals. 
                Each gate is a 3m panel. Any works on re-building a gate (i.e. replacing tin, ridge cap, etc.) 
                counts as one (1) gate. If re-built gates are not immediately installed, they are 
                to be slid into position to ensure they fit the OBH so there are no gaps/issues when 
                installing them during harvest. Re-building a gate does not include installing them.
              `,
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('gates_rebuilt'),
            },
            {
              name: 'gates_installed',
              label: 'Gates Installed',
              subtitle: `
                No. of Gates fixed into position. Each gate is a 3m panel. 
                Installing a gate is a seperate activity to re-building a gate.
              `,
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('gates_installed'),
            },
            {
              name: 'gates_removed',
              label: 'Gates Removed',
              subtitle: `
                No. of Gates taken out of position in the OBH (i.e. creating an opening in the OBH). 
                Each gate is a 3m panel.
              `,
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('gates_removed'),
            },
            {
              name: 'gates_required',
              label: 'Gates Still Required',
              subtitle: `
                No. of Gates that are still required to be re-built or checked (all gates 
                are to be slid into position to ensure they fit the OBH with no gaps/issues 
                so no works are required during harvest, and then placed against the OBH 
                wall ready for installation during harvest) to complete 100% of maintenance 
                on OBH. Each gate is a 3m panel.
              `,
              component: 'IntegerField',
              isHidden: !this.requiredConsumables.includes('gates_required'),
            },
          ],
          isValid: obhWorks.consumablesIsValid()
        },
        {
          name: 'grain',
          label: 'Grain',
          fields: [
            {
              name: 'has_grain_in_storage',
              label: 'Is there grain still in the OBH?',
              component: 'RadioSelectField',
              props: {
                options: [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ],
                multiple: false,
              }
            },
            {
              name: 'grain_peak_actual_start',
              label: '1st Peak',
              subtitle: 'Where the first peak of stack starts',
              component: 'IntegerField',
              isHidden: !this.form.has_grain_in_storage,
            },
            {
              name: 'grain_peak_actual_end',
              label: 'Peak Actual',
              component: 'IntegerField',
              isHidden: !this.form.has_grain_in_storage,
            },
            {
              name: 'has_tarps_on_stack',
              label: 'Are there tarps on the stack?',
              component: 'RadioSelectField',
              isHidden: !this.form.has_grain_in_storage,
              props: {
                options: [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ],
                multiple: false,
              }
            },
            {
              name: 'base_tarps_on_stack',
              label: 'Tarps on Stack',
              subtitle: 'Select the last 3 tarps that are on the stack',
              component: 'TarpsSelectField',
              isHidden: !this.form.has_grain_in_storage || !this.form.has_tarps_on_stack,
              value: tarpsOnStack,
              props: {
                multiple: true,
                generateSerialNumber: false,
                baseTarps: true,
              },
              update: (tarpsList) => {
                // Seperate tarpsList into existing tarps and new tarps (based on id)
                const existingTarps = tarpsList.filter(t => t.id);
                const newTarps = tarpsList.filter(t => !t.id);
                this.form.base_tarps_on_stack = existingTarps.map(t => t.id);
                this.form.new_tarps = newTarps;
              },
            }
          ],
          isValid: obhWorks.statusIsValid()
        },
        {
          name: 'struts',
          label: 'Struts',
          fields: [
            {
              name: 'storage_strut_numbering_correct',
              label: 'OBH Strut Numbering',
              subtitle: 'Is the OBH strut numbering clear and correct?',
              component: 'RadioSelectField',
              isHidden: hasPreviousWorksForStorage,
              props: {
                options: [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ],
                multiple: false,
              }
            },
            {
              name: 'storage_straight_length',
              label: 'Length of OBH Straight',
              subtitle: `
                Please list the length of the the OBH straight section 
                (i.e. what would be/is the last number of the straight struts before the end starts)
              `,
              component: 'IntegerField',
              isHidden: hasPreviousWorksForStorage,
            },
            {
              name: 'storage_numbering_correct',
              label: 'OBH Numbering',
              subtitle: 'Does the OBH have the correct number labeled as per Site OBH Maps?',
              component: 'RadioSelectField',
              isHidden: hasPreviousWorksForStorage,
              props: {
                options: [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ],
                multiple: false,
              }
            },
            {
              name: 'comments',
              label: 'Comments',
              component: 'TextareaField',
              subtitle: 'Please list any additional notes or comments.',
            },
          ],
          isValid: obhWorks.strutsIsValid()
        }
      ]
    }

  },
  created() {
    this.store = useRecordOBHWorksStore();
    this.store.init();

    this.OBHWorksStore = useOBHWorksStore();
  },
  methods: {
    updateField(field, value) {
      if (field.update) {
        field.update(value);
      } else {
        this.form[field.name] = value;
      }
    },

    async submit() {
      this.store.loading = true;
      this.store.submitted = true;
      try {
        await this.store.saveOBHWorks();
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      } finally {
        // Store a local copy of the form that we can use to restore the form
        // if the user wants to modify the data. Otherwise, we need to reset the
        // store to clear the form.
        this.localData = this.store.obh_works;
        // Reset the store
        this.store.reset(false);
        this.store.loading = false;
      }
    },

    recordAnother() {
      this.store.reset(true);
    },
    reSubmit() {
      this.store.obh_works = this.localData;
      this.submit();
    },
    returnToForm() {
      this.store.obh_works = this.localData;
      this.store.submitted = false;
    },
    selectActiveStepIndex(index) {
      this.setActiveStepIndex(index, true);
    },
    setActiveStepIndex(index, force = false) {
      // Set scroll position to top
      window.scrollTo(0, 0);
      const currentIndex = this.store.activeStepIndex;

      const obhWorks = this.getOBHWorks;
      if (obhWorks.obh_maintenance_is_complete === true) {
        // Skip "Grain" step if OBH maintenance is complete
        if (currentIndex === 2 && !force && index === 3) {
          index = 4;
        }
      }
      this.store.activeStepIndex = index;
    }
  }
}

</script>