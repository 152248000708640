import { default as axios } from './http';


class GCMSwingsAPI {

    getGCMSwings = async () => {
        // Fetch stacks from API
        console.log('Fetching GCM Swings from API...')
        const url = '/api/v1/gcm/gcm-swings/';
        const response = await axios.get(url)
        return response
    }

}

export default GCMSwingsAPI;
