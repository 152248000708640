import { useCommoditiesStore } from '@/stores/Commodities';
import { useSitesStore } from '@/stores/Sites';
import { useStoragesStore } from '@/stores/Storages';
import { useTarpInventoryStore } from '@/stores/TarpInventory';
import { useBaseTarpsStore } from '@/stores/BaseTarps';
import { useStacksStore } from '@/stores/Stacks';
import { useTarpRepairTypesStore } from '@/stores/TarpRepairTypes';
import { useTarpingReportsStore } from '@/stores/TarpingReports';
import { useTarpingWorksStore } from '@/stores/TarpingWorks';
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';
import { useTarpJoinsStore } from '@/stores/TarpJoins';
import { useTarpJoinUsersStore } from '../stores/TarpJoinUsers';
import { useRecordTarpJoinsStore } from '@/stores/RecordTarpJoins';
import { useTarpRepairsStore } from '@/stores/TarpRepairs';
import { useRecordTarpRepairsStore } from '@/stores/RecordTarpRepairs';
import { useStockpilingStore } from '@/stores/Stockpiling';
import { useStockpilingRequirementsStore } from '@/stores/StockpilingRequirements';
import { useRecordStockpilingStore } from '@/stores/RecordStockpiling';
import { useGCMManagementInputsStore } from '@/stores/GCMManagementInputs';
import { useGCMSwingsStore } from '@/stores/GCMSwings';
import { useNewTarpsInputsStore } from '../stores/NewTarpsInputs';
import { useRecordNewTarpsInputsStore } from '../stores/RecordNewTarpsInputs';
import { useSiteWorksStore } from '@/stores/SiteWorks';
import { useRecordSiteWorksStore } from '@/stores/RecordSiteWorks';
import { useOBHWorksStore } from '@/stores/OBHWorks';
import { useRecordOBHWorksStore } from '@/stores/RecordOBHWorks';
import { useSettingsStore } from '../stores/Settings';


export const loadAppData = async (awaitAPI = false) => {

  const commoditiesStore = useCommoditiesStore();
  const sitesStore = useSitesStore();
  const storagesStore = useStoragesStore();
  const tarpInventoryStore = useTarpInventoryStore();
  const baseTarpsStore = useBaseTarpsStore();
  const stacksStore = useStacksStore();
  const tarpRepairTypesStore = useTarpRepairTypesStore();
  const tarpingReportsStore = useTarpingReportsStore();
  const tarpingWorksStore = useTarpingWorksStore();
  const recordTarpingWorksStore = useRecordTarpingWorksStore();
  const tarpJoinsStore = useTarpJoinsStore();
  const recordTarpJoinsStore = useRecordTarpJoinsStore();
  const tarpJoinUsersStore = useTarpJoinUsersStore();
  const tarpRepairsStore = useTarpRepairsStore();
  const recordTarpRepairsStore = useRecordTarpRepairsStore();
  const stockpilingStore = useStockpilingStore();
  const stockpilingRequirementsStore = useStockpilingRequirementsStore();
  const recordStockpilingStore = useRecordStockpilingStore();
  const gcmManagementInputsStore = useGCMManagementInputsStore();
  const gcmSwingsStore = useGCMSwingsStore();
  const newTarpInputsStore = useNewTarpsInputsStore();
  const recordNewTarpsInputsStore = useRecordNewTarpsInputsStore();
  const siteWorksStore = useSiteWorksStore();
  const recordSiteWorksStore = useRecordSiteWorksStore();
  const obhWorksStore = useOBHWorksStore();
  const recordOBHWorksStore = useRecordOBHWorksStore();
  const settingsStore = useSettingsStore();

  const commoditiesLoading = commoditiesStore.loadCommodities(awaitAPI);
  const sitesLoading = sitesStore.loadSites(awaitAPI);
  const storagesLoading = storagesStore.loadStorages(awaitAPI);
  const tarpInventoryLoading = tarpInventoryStore.loadTarps(awaitAPI);
  const baseTarpsLoading = baseTarpsStore.loadBaseTarps(awaitAPI);
  const stacksLoading = stacksStore.loadStacks(awaitAPI);

  const tarpRepairTypesLoading = tarpRepairTypesStore.loadTarpRepairTypes(awaitAPI);
  const tarpingReportsLoading = tarpingReportsStore.loadTarpingReports(awaitAPI);
  const tarpingWorksLoading = tarpingWorksStore.loadTarpingWorks(awaitAPI);
  const recordTarpingWorksLoading = recordTarpingWorksStore.loadUnsavedTarpingWorksFromDB();
  const tarpJoinsLoading = tarpJoinsStore.loadTarpJoins(awaitAPI);
  const tarpJoinUsersLoading = tarpJoinUsersStore.loadTarpJoinUsers(awaitAPI);
  const recordTarpJoinsLoading = recordTarpJoinsStore.loadUnsavedTarpJoinsFromDB();
  const tarpRepairsLoading = tarpRepairsStore.loadTarpRepairs(awaitAPI);
  const recordTarpRepairsLoading = recordTarpRepairsStore.loadUnsavedTarpRepairsFromDB();
  const stockpilingLoading = stockpilingStore.loadStockpiling(awaitAPI);
  const stockpilingRequirementsLoading = stockpilingRequirementsStore.loadStockpilingRequirements(awaitAPI);
  const recordStockpilingLoading = recordStockpilingStore.loadUnsavedStockpilingFromDB();
  const gcmManagementInputsLoading = gcmManagementInputsStore.loadGCMManagementInputs(awaitAPI);
  const gcmSwingsLoading = gcmSwingsStore.loadGCMSwings(awaitAPI);
  const newTarpsInputsLoading = newTarpInputsStore.loadNewTarpsInputs();
  const recordNewTarpsInputsLoading = recordNewTarpsInputsStore.loadUnsavedNewTarpsInputsFromDB();
  const siteWorksLoading = siteWorksStore.loadSiteWorks(awaitAPI);
  const recordSiteWorksLoading = recordSiteWorksStore.loadUnsavedSiteWorksFromDB();
  const obhWorksLoading = obhWorksStore.loadOBHWorks(awaitAPI);
  const recordOBHWorksLoading = recordOBHWorksStore.loadUnsavedOBHWorksFromDB();

  console.log('Loading app data...')

  await Promise.all([
    sitesLoading, tarpInventoryLoading, baseTarpsLoading, stacksLoading, tarpingReportsLoading,
    tarpingWorksLoading, commoditiesLoading, storagesLoading, recordTarpingWorksLoading,
    tarpRepairTypesLoading, tarpJoinsLoading, recordTarpJoinsLoading, tarpJoinUsersLoading,
    tarpRepairsLoading, recordTarpRepairsLoading,
    stockpilingLoading, recordStockpilingLoading, stockpilingRequirementsLoading,
    gcmManagementInputsLoading, gcmSwingsLoading, newTarpsInputsLoading,
    recordNewTarpsInputsLoading, siteWorksLoading, recordSiteWorksLoading,
    obhWorksLoading, recordOBHWorksLoading,
  ]);

}

